import Drawer from '@/components/drawers'
import Loading from '@/components/widgets/Loading.vue'
import Vue from "vue";

export default {
  props: {
    opened: {
      type: Boolean,
      default: false
    },
    id: {
      type: String
    }
  },
  components: {
    Loading,
    Drawer
  },
  data() {
    return {
      loading: false,
      drawerOpened: false,
      uploadedFiles: []
    }
  },
  watch: {
    // observing the variable 'opened' for changes in its value
    'opened'(n, o) {
      if (n) {
        this.drawerOpened = n
      }
    }
  },
  methods: {
    // handling file changes with a drop event.
    handleDrop(event) {
      event.preventDefault();
      const files = event.dataTransfer.files;
      this.handleFiles(files);
    },

    // handling file changes with a click event.
    handleFileInput() {
      const files = this.$refs.fileInput.files;
      this.handleFiles(files);
    },

    // handling file uploads
    handleFiles(files) {
      for (let i = 0; i < files.length; i++) {
        this.uploadedFiles.push(files[i]);
      }
    },

    onCloseDrawer(event) {
      // assigning a new value to the array variable.
      this.uploadedFiles = []

      // closing the drawer component.
      this.drawerOpened = false
      this.$emit('close-event', { 
        status: true
      })
    },

    // upload images to APIs.
    async onSubmitForm() {
      // displaying an error message.
      if (this.uploadedFiles.length === 0) {
        this.onExceptionHandler('กรุณาเลือกไฟล์หลักฐานการชำระเงิน!')
        return false
      }

      try {
        // display the loading screen
        this.loading = true

        // Initial a formData
        const formData = new FormData()
        formData.append('uuid', this.id)

        // assigning a new image to formData
        for (let index = 0; index < this.uploadedFiles.length; index++) {
          const element = this.uploadedFiles[index];
          formData.append ('images[]', element)
        }
  
        // configuring the content headers for an Axios POST method
        let settings = { headers: { 'content-type': 'multipart/form-data' } }
        const result = await Vue.prototype.$axios.post(
          `${Vue.prototype.$host}/api/v1/document/preemption/uploadSlip/${this.id}`, 
          formData, settings)

        if(result.status === 200) {
          // hiding the loading screen
          this.loading = false    

          // assigning a new value to the array variable.
          this.uploadedFiles = []
  
          // use the sweetalert2 component.
          this.$swal({
            width: "29rem",
            padding: '1.825em',
            html: '<div class="d-flex flex-row align-items-center">' +
              '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
              '<div class="d-flex flex-column">'+
              '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>'+
              '<span class="text-base text-left text-gray-500">บันทึกข้อมูลเรียบร้อยแล้ว</span>'+
              '</div>'+
            '</div>',
              allowOutsideClick: false,
              focusConfirm: false,
              customClass: {
                popup: 'swal2-custom-rounded',
                closeButton: 'text-3xl swal2-custom-close',
                actions: 'justify-content-end',
                contant: 'd-flex flex-row justify-content-around px-2',
                title: 'd-none',
                confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
                cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
              },
              showCancelButton: false,
              confirmButtonText: "ตกลง",
              cancelButtonText: "ยกเลิก",
              showCloseButton: true,
          }).then(result => {
            // closing the drawer component.
            this.onCloseDrawer()
          })
        }
      } catch (error) {
        // hiding the loading screen
        this.loading = false

        // displaying an error message.
        this.onExceptionHandler(error.response.data.message);
      }
    }
  }
}
