import Vue from "vue"
import DatePicker from 'vue2-datepicker'
import Loading from '@/components/widgets/Loading.vue'
import SortedTablePlugin from "vue-sorted-table"
import GetPaid from '../widget-get-paid'
import UploadSlip from '@/components/widgets//widget-upload-slip'
import MenuList from '../widget-menu'
import Paginate from "@/components/widgets/pagination.vue";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  components: {
    Loading,
    MenuList,
    DatePicker,
    GetPaid,
    UploadSlip,
    Paginate
  },
  data: function() {
    return {
      role: "",
      branch: "",
      keyword_document_no: "",
      keyword_status: null,
      keyword_branch: null,
      keyword_is_insulator: false,
      loading: false, 
      busy: false,
      status: null,
      apiStatus: 'ไม่พบข้อมูล',
      totalItem: 0,
      getPaid: {
        Id: null,
        state: false
      },
      preemptionDocumentStatus:[
        { text: "รอสาขายืนยัน", value: 1 },
        { text: "กำลังเตรียมของ", value: 2 },
        { text: "กำลังจัดส่ง", value: 3 },
        { text: "จัดส่งสำเร็จ วางบิลแล้ว", value: 4 },
        { text: "ถูกยกเลิก", value: 5 },
        { text: "ชำระแล้ว รอตรวจสอบ", value: 6 },
        { text: "เสร็จสิ้น", value: 7 },
        { text: "ยกเลิก", value: 8 },
        { text: "รอชำระเงิน", value: 9 }
      ],
      perPage: 8,
      sortBy: 'name',
      currentPage: 1,
      currentData: {},
      sortDesc: false,
      fields: [
        { 
          key: 'document_no', 
          label: 'เลขที่เอกสาร', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'license_plate', 
          label: 'NO.เลขรถ', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'date', 
          label: 'ลงวันที่',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'type', 
          label: 'ประเภท',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'payment_terms', 
          label: 'การชำระเงิน', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'branch_id', 
          label: 'ลูกค้า',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'note', 
          label: 'หมายเหตุ', 
          sortable: true, 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2 ellipsis'
        },
        { 
          key: 'status', 
          label: 'สถานะ', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'action', 
          label: '#', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2'
        }
      ],
      items: [],
    }
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
  methods: {
    async getRole(){
      const res = await this.$store.dispatch('Auth/getRole', {}, { root: true })
      this.role = res.data.role
      this.branch = res.data.branch_id
    },
    handleGetPaidEvent(e) {
      this.getPaid.state = false
      this.onInitData(this.currentPage)
    },
    onMenuApproveOpenModal(event) {
      this.$swal({
        width: "29rem",
        padding: '1.825em',
        html: '<div class="d-flex flex-row align-items-center">' +
          '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
          '<div class="d-flex flex-column">'+
          '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>'+
          '<span class="text-base text-left text-gray-500">ท่านต้องการยืนยันการ `อนุมัติ` ใช่หรือไม่</span>'+
          '</div>'+
          '</div>',
          customClass: {
            popup: 'swal2-custom-rounded',
            closeButton: 'text-3xl swal2-custom-close',
            actions: 'justify-content-end',
            contant: 'd-flex flex-row justify-content-around px-2',
            title: 'd-none',
            confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
            cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
          },
          allowOutsideClick: false,
          focusConfirm: false,
          cancelButtonColor: '#dc4a38',
          cancelButtonText: 'ยกเลิก',
          confirmButtonText: "ยืนยัน",
          showCancelButton: true,
          showCloseButton: true,
      }).then(confirm => {
        if (confirm.value) { 
          this.onApprove(event.id)
        }
      })
    },
    onMenuRejectOpenModal(event) {
      this.$swal({
        width: "29rem",
        padding: '1.825em',
        html: '<div class="d-flex flex-row align-items-center">' +
          '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
          '<div class="d-flex flex-column">'+
          '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>'+
          '<span class="text-base text-left text-gray-500">ท่านต้องการยืนยันการ `ยกเลิก` ใช่หรือไม่</span>'+
          '</div>'+
        '</div>',
          allowOutsideClick: false,
          focusConfirm: false,
          customClass: {
            popup: 'swal2-custom-rounded',
            closeButton: 'text-3xl swal2-custom-close',
            actions: 'justify-content-end',
            contant: 'd-flex flex-row justify-content-around px-2',
            title: 'd-none',
            confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
            cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
          },
          cancelButtonColor: '#dc4a38',
          cancelButtonText: 'ยกเลิก',
          confirmButtonText: "ตกลง",
          showCancelButton: false,
          showCloseButton: true,
      }).then(async confirm => {
        if(confirm.value){
          this.onDecline(event.id)
        }
      })
    },
    oncreate() {
      this.$router.push('/order-centric/added')
    },
    greetCustomer() {
      this.$router.push('/order-centric/greet-customer')
    },
    async ondelete(e) {
      this.loading = true
      try {
        const result = await Vue.prototype.$axios.post(
          `${Vue.prototype.$host}/api/v1/document/preemption/cancel/${e.id}`
        )
        this.loading = false
        if (result.status === 200) {
          this.onInitData(this.currentPage)
        }
      } catch (error) {
        this.loading = false
        this.onExceptionHandler(error.response.data.message)
      }
    },
    async onInitData(currentPage) {
      // this.items = []
      this.currentPage = currentPage
      this.busy = true
      this.apiStatus = 'กำลังค้นหาข้อมูล..'
      try {
        const page = currentPage ? currentPage : 1
        const param = {
          page: page,
          keyword_document_no: this.keyword_document_no,
          keyword_status: this.keyword_status,
          keyword_branch: this.keyword_branch ? this.keyword_branch : '',
          keyword_is_insulator: this.keyword_is_insulator,
        }
        const result = await this.$store.dispatch('Documents/getAllPreemptionDocument', param, { root: true })
        this.busy = false
        this.apiStatus = 'ไม่พบข้อมูล'

        if (result) {
          this.currentData = result.data;
          this.items = result.data.data
          this.totalItem = result.data.total
        } else {
          this.items = []
          this.totalItem = 0
        }        
        
      } catch (error) {
        this.onExceptionHandler()
        this.busy = false
        this.apiStatus = 'ไม่พบข้อมูล'
        return []
      }
    },
    async onApprove(uuid) {
      this.busy = true
      this.loading = true
      try {
        const param = {
          uuid: uuid,
        }
        await this.$store.dispatch('Documents/approvePreemptionDocument', param, { root: true })
        this.busy = false
        this.loading = false
        this.onInitData(this.currentPage)
      } catch (error) {
        this.onExceptionHandler()
        this.loading = false
        this.busy = false
        return []
      }
    },
    async onDecline(uuid) {
      this.busy = true
      this.loading = true
      try {
        const param = {
          uuid: uuid,
        }
        await this.$store.dispatch('Documents/rejectPreemptionDocument', param, { root: true })
        this.busy = false
        this.loading = false
        this.onInitData(this.currentPage)

      } catch (error) {        
        this.loading = false
        this.busy = false
        this.onExceptionHandler()
        return []
      }
    },
    async getBranches() {
      const result = await this.$axios.get(this.$host + "/api/v1/branches", {
        headers: {
        },
      })
      this.branches = result.data
    },
  },
  mounted() {
    this.getBranches()
    this.onInitData(this.currentPage)
    this.getRole()
  }
}
